/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import Loading from '@domui-components/Loading';
import Metadata from '@domui-utils/workflow/metadata';

const SharingPermissionPage: React.FC = () => {
  const [sharingPermissionData, setSharingPermissionData] = useState([]);

  useEffect(() => {
    async function fetchSharingPermissionData() {
      const wfService = new Metadata();

      const response = await wfService.getSharingPermissionData();
      setSharingPermissionData(response);
    }
    fetchSharingPermissionData();
  }, []);

  if (!sharingPermissionData) {
    return <Loading />;
  }

  return (
    <div>
      {!!sharingPermissionData?.length && (
        <div style={{ alignItems: 'center' }} className="max-w-4xl mb-8">
          <h3>Sharing Permission Page</h3>
          <ul>
            {sharingPermissionData.map((item: any) => (
              <div key={item.id}>
                <li className="mb-0 last:mb-4">
                  - id : {item.id}- value : {item.value}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SharingPermissionPage;
